import styles from './ControlButton.module.css'
import {useState} from 'react';

import buttonPlusIdle from '../../../image/btn_plus_idle.png'
import buttonPlusHover from '../../../image/btn_plus_hover.png'
import buttonMinusIdle from '../../../image/btn_minus_idle.png'
import buttonMinusHover from '../../../image/btn_minus_hover.png'

const ControlButton = props => {
  const [over, setOver] = useState(false)

  const getSource = () => {
    const {isPlus = true} = props

    if (isPlus === true) {
      return over ? buttonPlusHover : buttonPlusIdle
    } else {
      return over ? buttonMinusHover : buttonMinusIdle
    }
  }

  const getAtl = () => {
    const {isPlus = true} = props

    if (isPlus === true) {
      return 'Plus button'
    } else {
      return 'Minus button'
    }
  }

  return (
    <button
      className={styles.btn}
      onMouseOver={() => setOver(true)}
      onMouseOut={() => setOver(false)}
      onClick={() => {
        props.onClick()
      }}>
      <img
        src={getSource()}
        width={over ? '45' : '50'}
        alt={getAtl()}
      />
    </button>
  );
};

export default ControlButton;