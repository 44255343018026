import {useState} from 'react';
import styles from './QuantityControl.module.css';
import ControlButton from './ControlButton/ControlButton';

const MAX_QUANTITY = 5

const QuantityControl = props => {

  const onPlus = () => {
    let newQuantity = props.quantity + 1
    if (newQuantity <= MAX_QUANTITY) {
      props.onQuantityChanged(newQuantity)
    }
  }

  const onMinus = () => {
    let newQuantity = props.quantity - 1
    if (newQuantity > 0) {
      props.onQuantityChanged(newQuantity)
    }
  }

  return (
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <ControlButton isPlus={false} onClick={onMinus} />
      <span className={styles.Text}>{props.quantity}</span>
      <ControlButton isPlus={true} onClick={onPlus} />
    </div>
  );
};

export default QuantityControl;