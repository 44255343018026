import React from 'react';
import fontClasses from '../../css/Font.module.css';
import classes from './MainPageMintDesc.module.css';

const MainPageMintDesc = props => {
  const style = {
    position: 'relative',
    maxWidth: '500px',
  };

  const styleFollow = {
    position: 'relative',
    maxWidth: '500px',
    textAlign: 'center',
  };

  return (
    <div className={classes.MainPageMintDesc}>
      <p className={fontClasses.Text} style={style}>
        Towns for your Goblin to stay in. Come down down to goblin town!
        <br />
        1st one free mint and subsequent mints 0.005 ETH.
      </p>
      <hr className={classes.Divider} />
      <p className={fontClasses.Text} style={style}>
        We are reserving 500 Goblintown.town just because.
      </p>
      <hr className={classes.Divider} />
      <p className={fontClasses.Text} style={style}>
        No roadmap. No Discord. No utility. CC0.
      </p>
      <hr className={classes.Divider} />
      <p className={fontClasses.Text} style={styleFollow}>
        #GOBLINFOLLOWGOBLIN
      </p>
    </div>
  );
};

export default MainPageMintDesc;
