export const connectWallet = async () => {
  // console.log('connect wallet');
  if (window.ethereum) {
    try {
      const addresses = await window.ethereum.request({
        method: 'eth_requestAccounts',
      });
      const obj = {
        address: addresses[0],
        connected: true,
        status: '',
      };
      return obj;
    } catch (error) {
      return {
        address: '',
        conencted: false,
        status: error.message,
      };
    }
  } else {
    return {
      address: '',
      connected: false,
      status: (
        <a href='https://metamask.io/' target='_blank' rel='noreferrer'>
          {' '}
          You need to install Metamask
        </a>
      ),
    };
  }
};
