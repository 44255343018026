import {useState} from 'react';
import styles from './socialMediaButtonStyles.module.css';

const SocialMediaButton = ({link, iconImage}) => {
  const [over, setOver] = useState(false);

  return (
    <>
      <a href={link} target='_blank' rel='noreferrer'>
        <button
          className={styles.socialMediaBtn}
          onMouseOver={() => setOver(true)}
          onMouseOut={() => setOver(false)}>
          <img
            src={iconImage}
            width={'80'}
            alt='Opensea button'
            style={over ? {
              transform: 'rotate(10deg)',
              transition: '.2s'
            } : {}}
          />
        </button>
      </a>
    </>
  );
};

export default SocialMediaButton;
