import React, {useEffect, useState} from 'react';
import classes from './MainPageAction.module.css';
import ConnectButton from '../../Components/ConnectButton/ConnectButton';
import MintButton from '../../Components/MintButton/MintButton';
import QuantityControl from '../../Components/QuantityControl/QuantityControl';
import SocialMediaButtonContainer from '../../Components/SocialMediaButton/SocialMediaButtonContainer';
// import {checkEligibility} from '../../Utils/checkEligibility';
// import {fetchSignature} from '../../Request/signature';

const styles = {
  mintedText: {
    color: 'white',
    fontFamily: 'blue-goblet',
    fontSize: '1.5em',
  },
};

const MainPageAction = props => {
  const ethereum = window.ethereum || {}
  const [status, setStatus] = useState('');
  const [wallet, setWallet] = useState('');
  const [quantity, setQuantity] = useState(1)
  const [connected, setConnected] = useState(false);
  // const [isEligible, setIsEligible] = useState(ethereum.isTrust ? true : false);
  // const [signature, setSignature] = useState(undefined);
  // const [selectedAddress, setSelectedAddress] = useState(undefined);

  // useEffect(() => {
  //   if (connected) {
  //     const fetchData = async () => {
  //       if (ethereum.selectedAddress !== selectedAddress) {
  //         let isEligibleResp = await checkEligibility()
  //         // let [signatureResp, isEligibleResp] = await Promise.all([
  //         //   fetchSignature(ethereum.selectedAddress),
  //         //   checkEligibility(),
  //         // ]);

  //         setIsEligible(isEligibleResp);
  //         // setSignature(signatureResp);
  //         setSelectedAddress(ethereum.selectedAddress);
  //       }

  //       // console.log('useEffect setIsEligible', isEligible);
  //     };

  //     fetchData();
  //   }
  // }, [connected, ethereum.selectedAddress]);

  // useEffect(() => {
  //   if (ethereum.selectedAddress !== selectedAddress) {
  //     setSignature(undefined);
  //   }
  // }, [ethereum.selectedAddress]);

  return (
    <div className={classes.MainPageAction}>
      <ConnectButton
        setStatus={setStatus}
        setConnected={setConnected}
        setWallet={setWallet}
      />
      {/* <div style={styles.mintedText}>Git reedy for Goblintowntown !</div> */}
      {connected && (
        <div>
          <QuantityControl quantity={quantity} onQuantityChanged={setQuantity} />
          <MintButton isConnected={connected} quantity={quantity} />
        </div>
      )}
      <SocialMediaButtonContainer />
    </div>
  );

  // connected, hasSignature, isEligible - show mint button
  // connected, hasSignature, isNotEligible - show 'git reedy'
  // connected, noSignature - 'public mint is coming soon'
  // notConnected - show connect button
};

export default MainPageAction;
