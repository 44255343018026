import styles from './connectButtonStyles.module.css';
import {useState, useEffect} from 'react';

import {connectWallet} from '../../Utils/connectWallet';
import {getWalletStatus} from '../../Utils/getWalletStatus';

const ConnectButton = ({setStatus, setConnected, setWallet}) => {
  const [walletAddress, setWalletAddress] = useState('');
  const [walletStatus, setWalletStatus] = useState('');

  const handleConnect = async () => {
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWalletStatus(walletResponse.status);
    setConnected(walletResponse.connected);
    setWallet(walletResponse.address);
    setWalletAddress(walletResponse.address);
  };

  useEffect(() => {
    const checkWalletStatus = async () => {
      const walletResponse = await getWalletStatus();
      setStatus(walletResponse.status);
      setWalletStatus(walletResponse.status);
      setConnected(walletResponse.connected);
      setWalletAddress(walletResponse.address);
      setWallet(walletResponse.address);
    };

    const walletListener = () => {
      if (window.ethereum) {
        window.ethereum.on('accountsChanged', accounts => {
          checkWalletStatus();
        });
      }
    };

    checkWalletStatus();
    walletListener();
  }, [setConnected, setStatus, setWallet]);

  const getStyle = (isConnected) => {
    let style = {}

    if (isConnected === false) {
      style.display = 'none'
    }

    return style
  }

  return (
    // <div className={styles.connectBtnContainer}>
    <button
      className={styles.connectBtn}
      style={getStyle(walletAddress.length === 0)}
      onClick={handleConnect}>
      {/* <img
          src={iconMintIdle}
          width='200'
          alt='Connect wallet button'
        /> */}
      {walletAddress.length === 0
        ? 'Connect Wallet'
        : 'Connected: ' +
        String(walletAddress).substring(0, 6) +
        '...' +
        String(walletAddress).substring(38)}
    </button>

    // </div>
  );
};

export default ConnectButton;
