import React from 'react';
import classes from './MainPageShowcase.module.css'

const MainPageShowcase = (props) => {

  return (
    <div style={{
      width: '100%',
      position: 'absolute'
    }}>
      <div className={classes.MainPageShowcase} />
    </div>
  )
}

export default MainPageShowcase;