import styles from './socialMediaButtonStyles.module.css';

import iconOpensea from '../../image/ico_opensea.png';
import iconTwitter from '../../image/ico_twitter.png';
import SocialMediaButton from './SocialMediaButton';

const SocialMediaButtonContainer = ({link}) => {
  return (
    <div className={styles.socialMediaContainer}>
      <SocialMediaButton link={'https://twitter.com/goblintowntown'} iconImage={iconTwitter} />
      <SocialMediaButton link={'https://opensea.io/collection/goblintown-town'} iconImage={iconOpensea} />
    </div>
  );
};

export default SocialMediaButtonContainer;
