import styles from './mintButtonStyles.module.css';
import {useState, useEffect} from 'react';

import iconMintIdle from '../../image/ico_mint_idle.png';
import iconMintHover from '../../image/ico_mint_hover.png';
import mint from '../../Utils/interact';

const MintButton = props => {
  const [over, setOver] = useState(false);

  const getStyle = isConnected => {
    let style = {};

    if (isConnected === false) {
      style.display = 'none';
    }

    return style;
  };

  return (
    <button
      className={styles.mintBtn}
      style={getStyle(props.isConnected)}
      onMouseOver={() => setOver(true)}
      onMouseOut={() => setOver(false)}
      onClick={() => {
        mint(props.quantity);
      }}>
      <img
        src={over ? iconMintHover : iconMintIdle}
        width={over ? '200' : '200'}
        alt='Mint button'
      />
    </button>
  );
};

export default MintButton;
