import React, { useEffect, useState } from 'react';
import classes from './MainPageTitle.module.css';
import titleLight from '../../image/web_title_light.png';
import titleLight1 from '../../image/web_title_light_f1.png';
import titleLight2 from '../../image/web_title_light_f2.png';
import titleLight3 from '../../image/web_title_light_f3.png';
import titleLight4 from '../../image/web_title_light_f4.png';

const MainPageTitle = props => {
  const [titleImageIndex, setTitleImageIndex] = useState(0);
  const titleImages = [
    titleLight,
    titleLight1,
    titleLight2,
    titleLight3,
    titleLight4,
  ];

  useEffect(() => {
    const updateTitleImageIndex = () => {
      // console.log('update title', titleImageIndex);
      setTitleImageIndex(
        titleImageIndex => (titleImageIndex + 1) % titleImages.length
      );
    };

    const interval = setInterval(updateTitleImageIndex, 100);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const getTitleStyle = scrollPosition => {
    // console.log('scroll Pos', 'i', titleImages[titleImageIndex])
    const stopPosition = 1750;
    return {
      top: scrollPosition > stopPosition ? `${stopPosition}px` : '0px',
      position: scrollPosition > stopPosition ? 'absolute' : 'fixed',
      backgroundImage: `url(${titleImages[titleImageIndex]})`,
      opacity: scrollPosition > 100 ? (scrollPosition - 100) / 100 : 0,
    };
  };

  return (
    <div
      className={classes.BackgroundTitle}
      style={getTitleStyle(props.scrollPos)}
    />
  );
};

export default MainPageTitle;
