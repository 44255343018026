import React from 'react';
import classes from './MainPage.module.css';
import useScrollPosition from '../UseScrollPosition';
import MainPageTitle from './MainPageTitle/MainPageTitle';
import MainPageMintDesc from './MainPageMintDesc/MainPageMintDesc';
import MainPageAction from './MainPageAction/MainPageAction';
import MainPageShowcase from './MainPageShowcase/MainPageShowcase';

const MainPage = () => {
  const pos = useScrollPosition(0.4);
  const initialTownPosY = 400;

  const getStyle = (positionY, initialPosY, maxScrollPos) => {
    let scrollPosition = positionY;
    if (maxScrollPos !== undefined) {
      scrollPosition = positionY > maxScrollPos ? maxScrollPos : positionY;
    }

    return {
      backgroundRepeat: 'no-repeat',
      top: scrollPosition + initialPosY,
      position: 'absolute',
    };
  };

  const getInvertStyle = (positionY, initialPosY, maxScrollPos = 1000) => {
    positionY *= -1;

    let scrollPosition = positionY < maxScrollPos ? maxScrollPos : positionY;
    // if (maxScrollPos !== -140)
    //   console.log('scroll Pos', scrollPosition, 'posY', positionY);
    return {
      backgroundRepeat: 'no-repeat',
      top: scrollPosition + initialPosY,
      position: 'absolute',
    };
  };

  return (
    <div className={classes.BackgroundContainer}>
      <MainPageTitle scrollPos={useScrollPosition(1)} />

      <div className={classes.BackgroundTop} />

      <div
        className={classes.BackgroundClouds}
        style={getStyle(useScrollPosition(0.33), 280 + initialTownPosY)}
      />
      <div
        className={classes.BackgroundHorizon}
        style={getStyle(useScrollPosition(0.55), 550 + initialTownPosY, 1000)}
      />
      <div
        className={classes.BackgroundCityglow}
        style={getStyle(pos, 100 + initialTownPosY)}
      />

      <div
        className={classes.BackgroundTown01}
        style={getStyle(useScrollPosition(0.2), 910 + initialTownPosY)}
      />
      <div
        className={classes.BackgroundTown02}
        style={getStyle(useScrollPosition(0.25), 790 + initialTownPosY)}
      />
      <div
        className={classes.BackgroundTown03}
        style={getStyle(useScrollPosition(0.3), 720 + initialTownPosY)}
      />
      <div
        className={classes.BackgroundTown04}
        style={getStyle(useScrollPosition(0.33), 460 + initialTownPosY)}
      />
      <div
        className={classes.BackgroundTown05}
        style={getStyle(useScrollPosition(0.37), 280 + initialTownPosY)}
      />
      <div
        className={classes.BackgroundTown06}
        style={getStyle(useScrollPosition(0.4), 500 + initialTownPosY)}
      />

      <div
        className={classes.BackgroundFarland}
        style={getStyle(useScrollPosition(0.16), 1050 + initialTownPosY)}
      />
      <div
        className={classes.BackgroundForeground}
        style={getInvertStyle(
          useScrollPosition(0.2),
          1270 + initialTownPosY,
          -140
        )}
      />

      <div
        className={classes.Blender}
        style={getStyle(useScrollPosition(0.55), 350, 500)}
      />

      {/* Page content here */}
      <div
        className={classes.BackgroundCross}
        style={getInvertStyle(
          useScrollPosition(0.1),
          1350 + initialTownPosY,
          -280
        )}>
        <MainPageShowcase />
        <div className={classes.ControlGroup}>
          <MainPageMintDesc scrollPos={useScrollPosition(1)} />
          <MainPageAction />
        </div>
      </div>
    </div>
  );
};

export default MainPage;
