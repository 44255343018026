import web3 from 'web3';
import contractABI from '../ABI/abi.json';
import {CONTRACT_ADDRESS} from '../constants/constants';

const contractAddress = CONTRACT_ADDRESS;
const priceInHex = [
  '0x0',  // dummy record
  '0x11C37937E08000',
  '0x2386F26FC10000',
  '0x354A6BA7A18000',
  '0x470DE4DF820000',
  '0x58D15E17628000'
]

export const mint = async (quantity) => {
  // console.log('minting', contractAddress);

  let ethereum = window.ethereum || {}
  window.web3 = new web3(ethereum);

  window.contract = new window.web3.eth.Contract(
    contractABI.abi,
    contractAddress
  );
  // console.log(window.contract);
  let msgValue = priceInHex[quantity]

  if (ethereum.isMetaMask === true) {
    const balance = await window.contract.methods
      .goblins(window.ethereum.selectedAddress)
      .call()

    if (balance.toString() === '0') {
      msgValue = priceInHex[quantity - 1]
    }
  }
  //set up your Ethereum transaction
  const transactionParameters = {
    to: contractAddress, // Required except during contract publications.
    from: window.ethereum.selectedAddress, // must match user's active address.
    data: window.contract.methods.meen(quantity).encodeABI(), //make call to NFT smart contract
    value: msgValue,
  };

  //sign the transaction via Metamask
  try {
    const txHash = await window.ethereum.request({
      method: 'eth_sendTransaction',
      params: [transactionParameters],
    });
    return {
      success: true,
      status:
        '✅ Check out your transaction on Etherscan: https://etherscan.io/tx/' +
        txHash,
    };
  } catch (error) {
    return {
      success: false,
      status: '😥 Something went wrong: ' + error.message,
    };
  }
};

export default mint;
